import {
  AppBar,
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Toolbar,
  Typography,
} from '@material-ui/core';
import NoSsr from '@material-ui/core/NoSsr';
import ArrowBackIcon from '@material-ui/icons/ArrowBackRounded';
import CallIcon from '@material-ui/icons/Call';
import CheckIcon from '@material-ui/icons/Check';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { navigate } from 'components/Link';
import qs from 'query-string';
import React, { Suspense, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useAPI, useSnackbar } from 'utils/customHooks';
import fetch from 'utils/fetch';
import { isInIframe } from 'utils/utils';

export default ({ location }) => {
  const { salesManId, retailerId, date } = qs.parse(location.search);
  const [selectedRetailer, setSelectedRetailer] = useState();
  const [notif] = useSnackbar();
  const { data: retailersData } = useQuery(
    `auditing_retailers_data${salesManId}${date}`,
    () =>
      fetch(`/retailer/audit/salesman/${salesManId}/retailer?date=${date}`)
        .then((res) => res)
        .catch((err) => {
          notif(err.message, { variant: 'error' });
        }),
    {
      suspense: false,
    }
  );
  useEffect(() => {
    if (retailersData) {
      setSelectedRetailer(retailersData.find((r) => r.retailerId == retailerId));
    }
    return () => {
      setSelectedRetailer();
    };
  }, [retailerId, retailersData]);
  return (
    <>
      {!isInIframe() && (
        <AppBar position="sticky">
          <Toolbar variant="dense">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => navigate(`/ct/retailers/?salesManId=${salesManId}`)}
              edge="start"
            >
              <ArrowBackIcon />
            </IconButton>

            <Typography variant="h6">Audit For Retailer</Typography>
          </Toolbar>
        </AppBar>
      )}
      <NoSsr>
        <Suspense fallback={<LinearProgress color="secondary" />}>
          {selectedRetailer && (
            <RetailerAudit retailer={selectedRetailer} salesManId={salesManId} />
          )}
        </Suspense>
      </NoSsr>
    </>
  );
};

const RetailerAudit = ({ retailer, salesManId }) => {
  const [fetch] = useAPI();
  if (retailer && retailer.auditId === undefined) {
    return (
      <Box m={2}>
        <Typography>Audits not found. Audit this retailer First.</Typography>
      </Box>
    );
  }
  const rejectAudit = (auditId) => {
    fetch(
      {
        method: 'post',
        url: `/retailer/audit/${auditId}/reject`,
      },
      () => {
        const redirect = window.confirm('Audit rejected successfully! Want to go back?');
        if (redirect) navigate(`/ct/auditingDashboard`);
      }
      // (error) => console.log('Error while creating new report :', error)
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={7} lg={8} xl={8}>
        <Box p={2} minWidth={4 / 6} display="flex" flexDirection="column" align="center">
          <Box display="flex" align="center">
            {retailer.images.map((src, index) => {
              return (
                <Box key={index} minWidth={1 / 2}>
                  <img src={src} style={{ maxHeight: '40vh' }} />
                </Box>
              );
            })}
          </Box>
          <Box mt={2} display="flex" alignItems="center" justifyContent="center">
            <Box m={1}>
              <Button
                onClick={() =>
                  navigate(
                    `/ct/retailers/auditqa?salesManId=${salesManId}&retailerId=${retailer.retailerId}&auditId=${retailer.auditId}`
                  )
                }
                variant="outlined"
                color="primary"
                startIcon={<CheckIcon />}
              >
                Accept
              </Button>
            </Box>
            <Box m={1}>
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<DeleteForeverIcon />}
                onClick={() => rejectAudit(retailer.auditId)}
              >
                Reject
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={5} lg={4} xl={4}>
        <Box p={2} minWidth={2 / 6}>
          <Box>
            <Typography variant="h6">Contact Person : {retailer.name} </Typography>
          </Box>
          <Box>
            <Typography variant="h6">
              Phone no. : {retailer.phone}
              <IconButton
                color="primary"
                href={`tel:${retailer.phone}`}
                aria-label="call"
                component="a"
              >
                <CallIcon />
              </IconButton>
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6">Channel : {retailer.channel}</Typography>
          </Box>
          <Box>
            <Typography variant="h6">
              Address : <span>{retailer.address}</span>
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
