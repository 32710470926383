import React, { useState } from 'react';
import ScheduleSelector from 'react-schedule-selector';
import moment from 'moment';
import { formatDate } from 'utils/utils';
import styled from 'styled-components';

const ScheduleSelectorCard = styled.div`
  border-radius: 20px;
  box-shadow: 5px 2px 20px rgba(0, 0, 0, 0.15);
  padding: 0 20px 20px 10px;
  // width: 45%;
  // max-width: 45%;
  & > * {
    flex-grow: 1;
  }
`;

export default function TimeWindowSelector(props) {
  const { onChange } = props;
  const [state, setState] = useState([]);

  const getMoment = (str) => moment(str, 'HH:mm');
  const renderCustomDateCell = (time, selected, innerRef) => (
    <>
      {/* {selected ? '✅' : '❌'} */}
      {selected ? (
        <div ref={innerRef} style={{ width: '30px', height: '25px', background: '#599af2' }}></div>
      ) : (
        <div ref={innerRef} style={{ width: '30px', height: '25px', background: '#dbedff' }}></div>
      )}
    </>
  );
  const mergeIntervals = (arr) => {
    const res = [];
    for (let i = 0; i < arr.length; i++) {
      const ind = i;
      const s = getMoment(arr[ind]);
      while (getMoment(arr[i]).isSame(getMoment(arr[i + 1]).subtract(1, 'h'))) {
        i += 1;
      }
      ind === i
        ? res.push(`${s.format('HH:mm')} - ${s.add(1, 'h').format('HH:mm')}`)
        : res.push(`${s.format('HH:mm')} - ${getMoment(arr[i]).add(1, 'h').format('HH:mm')}`);
    }
    // console.log(res);
    // console.log(arr);
    return res;
  };
  const handleChange = (newSchedule) => {
    const groupedDate = newSchedule.reduce((res, scd) => {
      res[formatDate(scd)] = [...(res[formatDate(scd)] || []), moment(scd).format('HH:mm')].sort();
      return res;
    }, {});
    const makeIntervals = Object.keys(groupedDate).reduce((res, key) => {
      res[moment(key).format('dddd')] = mergeIntervals(groupedDate[key]);
      return res;
    }, {});
    setState(newSchedule);
    onChange(makeIntervals);
  };

  return (
    <ScheduleSelectorCard>
      <ScheduleSelector
        {...props}
        selection={state}
        numDays={7}
        minTime={8}
        maxTime={20}
        margin={2}
        onChange={handleChange}
        renderDateCell={renderCustomDateCell}
      />
    </ScheduleSelectorCard>
  );
}
