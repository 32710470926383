import {
  AppBar,
  Box,
  Grid,
  IconButton,
  LinearProgress,
  Toolbar,
  Typography,
} from '@material-ui/core';
import cyan from '@material-ui/core/colors/cyan';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';
import NoSsr from '@material-ui/core/NoSsr';
import ArrowBackIcon from '@material-ui/icons/ArrowBackRounded';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import DatePicker from 'components/inputs/DatePicker';
import { navigate } from 'components/Link';
import qs from 'query-string';
import React, { Suspense } from 'react';
import { useQuery } from 'react-query';
import { ga, useGDispatch } from 'state/store';
import { useSnackbar } from 'utils/customHooks';
import fetch from 'utils/fetch';
import { format1, isInIframe } from 'utils/utils';

const useStyles = makeStyles(() => ({
  AcceptedClosed_NotConnected: {
    borderColor: grey[500],
  },
  Audited: {
    borderColor: cyan[500],
  },
  Audited_Offbeat: {
    borderColor: yellow[500],
  },
  AcceptedClosed: {
    borderColor: red[500],
  },
  NotAcceptedClosed: {
    borderColor: green[500],
  },
}));

export default ({ hideFilers, location }) => {
  const { salesManId, date } = qs.parse(location.search);
  const gDispatch = useGDispatch();

  return (
    <>
      {!isInIframe() && (
        <AppBar position="sticky">
          <Toolbar variant="dense">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => navigate('/ct/auditingDashboard')}
              edge="start"
            >
              <ArrowBackIcon />
            </IconButton>

            <Box display="flex" justifyContent="space-between" minWidth={1}>
              <Box p={1}>
                <Typography variant="h6">Customers</Typography>
              </Box>
              {!hideFilers && (
                <DatePicker
                  value={date}
                  color="white"
                  onChange={(selectedDate) => {
                    if (selectedDate && !isNaN(selectedDate)) gDispatch([ga.DATE, selectedDate]);
                  }}
                />
              )}
            </Box>
          </Toolbar>
        </AppBar>
      )}
      <NoSsr>
        <Suspense fallback={<LinearProgress color="secondary" />}>
          <AuditRetailers salesManId={salesManId} date={date} />
        </Suspense>
      </NoSsr>
    </>
  );
};

function AuditRetailers({ salesManId, date }) {
  const [notif] = useSnackbar();
  const { data: retailersData } = useQuery(`auditing_retailers_data${salesManId}${date}`, () =>
    fetch(`/retailer/audit/salesman/${salesManId}/retailer?date=${date}`)
      .then((res) => res)
      .catch((err) => {
        notif(err.message, { variant: 'error' });
      })
  );

  const auditedRetailers = retailersData?.filter((ret) => ret.auditId !== undefined);
  if (auditedRetailers && auditedRetailers.length == 0) {
    return (
      <Box m={2}>
        <Typography>No audited customers found.</Typography>
      </Box>
    );
  }
  return (
    <Box>
      <Grid container>
        {auditedRetailers?.map((retailer) => (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={retailer.retailerId}>
            <RetailerCard retailer={retailer} salesManId={salesManId} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

const getColorCode = (state, isConnected, offbeat) => {
  return state === 'AcceptedClosed' && isConnected === false
    ? `${state}_NotConnected`
    : state === 'Audited' && offbeat === true
    ? `${state}_Offbeat`
    : state;
};

function RetailerCard({ retailer, salesManId }) {
  const {
    retailerId,
    code,
    name,
    state,
    channel,
    // timestamp,
    isConnected,
    offbeat,
    distanceFromGeotag,
  } = retailer;
  const classes = useStyles();
  const colorCode = getColorCode(state, isConnected, offbeat);

  return (
    <Box
      m={1}
      p={1}
      px={2}
      w={1}
      borderRadius={6}
      boxShadow={1}
      borderLeft={6}
      className={classNames(classes[colorCode])}
      onClick={() =>
        navigate(`/ct/retailers/audit?salesManId=${salesManId}&retailerId=${retailerId}`)
      }
    >
      <Box display="flex">
        <Box minWidth={2 / 3}>
          <Typography variant="h6">{code}</Typography>
        </Box>
        <Box minWidth={1 / 3} align="right">
          <Typography color="textSecondary">{channel}</Typography>
        </Box>
      </Box>
      <Box display="flex">
        <Box minWidth={2 / 3}>
          <Typography>{name}</Typography>
        </Box>
        <Box minWidth={1 / 3} align="right">
          <Typography>{format1(distanceFromGeotag || 0)} m</Typography>
        </Box>
      </Box>
    </Box>
  );
}
