import {
  AppBar,
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Toolbar,
  Typography,
} from '@material-ui/core';
import Input from '@material-ui/core/Input';
import NoSsr from '@material-ui/core/NoSsr';
import ArrowBackIcon from '@material-ui/icons/ArrowBackRounded';
import CallIcon from '@material-ui/icons/Call';
import CustomSelect from 'components/inputs/CustomSelect';
import { navigate } from 'components/Link';
import TimeWindowSelector from 'components/ScheduleSelector';
import moment from 'moment';
import qs from 'query-string';
import React, { Suspense, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useAPI, useSnackbar } from 'utils/customHooks';
import fetch from 'utils/fetch';
import { isEmpty, isInIframe } from 'utils/utils';

const listOfReasons = [
  { label: 'Did not pick up the call' },
  { label: 'Mobile Switched off / Not Reachable' },
  { label: 'Wrong Number' },
  { label: 'Number not available' },
];
const binaryInput = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
];

const listOfQAs = [
  {
    label: 'The shop is open now (Y/N) ?',
    key: 'isOpen',
    isSubMenuGrid: true,
    sub_label: 'Please select a closing window',
    sub_key: 'closedWindow',
  },
  {
    label: 'The shop will be opening in near future (Y/N) ?',
    key: 'willOpen',
    isSubMenu: true,
    sub_key: 'willOpenOnDate',
    sub_label: 'The shop open in Days ?',
    values: [
      { label: '15 Days', value: moment().add(15, 'd').format('YYYY-MM-DD') },
      { label: '1 Month', value: moment().add(1, 'M').format('YYYY-MM-DD') },
      { label: '2 Month', value: moment().add(2, 'M').format('YYYY-MM-DD') },
      { label: '3 Month', value: moment().add(3, 'M').format('YYYY-MM-DD') },
    ],
  },
  {
    label: 'The shop is permanently closed (Y/N) ?',
    key: 'permanentlyClosed',
    options: [{ label: 'Yes', value: 'Yes' }],
  },
];

const SequentialQuestions = (props) => {
  const { onSequentialChange, disableSubMenu, disableNextMenu, listOfQs } = props;
  const initialqState = {
    isOpen: '', //true/false
    willOpen: '', // true/false
    willOpenOnDate: '', // Date
    closedWindow: [], // [DateTime]
    permanentlyClosed: '', //true/false
  };
  const [qState, setqState] = useState(initialqState);
  const [disableIndex, setDisableIndex] = useState(0);

  const handleChange = ({ value, key, index }) => {
    const eraseState = {};
    if (disableNextMenu(value)) {
      eraseState[listOfQs[index].sub_key] = '';
    }
    for (let i = index + 1; i < listOfQs.length; i++) {
      eraseState[listOfQs[i].key] = '';
      eraseState[listOfQs[i].sub_key] = '';
    }
    setDisableIndex(() => (disableNextMenu(value) ? index + 1 : index));
    const newState = { ...qState, [key]: value, ...eraseState };
    setqState(newState);
    onSequentialChange(newState);
  };

  return (
    <>
      {listOfQs.map((item, index) => {
        return (
          <React.Fragment key={index}>
            {!(index > disableIndex) && (
              <>
                <Grid item xs={12} md={6}>
                  {item.label}
                </Grid>

                <Grid item xs={12} md={6}>
                  <CustomSelect
                    value={qState[item.key]}
                    items={item.options || binaryInput}
                    disabled={index > disableIndex}
                    onChange={(event) =>
                      handleChange({ value: event.target.value, key: item.key, index })
                    }
                  />
                </Grid>
                {!disableSubMenu(qState[item.key]) && (
                  <>
                    <Grid item xs={12} md={6}>
                      {item.sub_label}
                    </Grid>
                    {item.isSubMenu && (
                      <Grid item xs={12} md={6}>
                        <CustomSelect
                          value={qState[item.sub_key]}
                          items={item.values}
                          disabled={disableSubMenu(qState[item.key])}
                          onChange={(event) =>
                            handleChange({ value: event.target.value, key: item.sub_key, index })
                          }
                        />
                      </Grid>
                    )}
                    {item.isSubMenuGrid && (
                      <TimeWindowSelector
                        dateFormat="ddd"
                        onChange={(value) =>
                          handleChange({ value: value, key: item.sub_key, index })
                        }
                      />
                    )}
                  </>
                )}
              </>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

const Questionnaires = (props) => {
  const { connected, onReasonChange, reason, onSequentialChange, listOfQs } = props;
  return connected === 'No' ? (
    <>
      <Grid item xs={12} md={6}>
        Please provide a reason
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomSelect items={listOfReasons} value={reason} onChange={onReasonChange} />
      </Grid>
    </>
  ) : connected === 'Yes' ? (
    <SequentialQuestions
      disableSubMenu={(value) => value !== 'Yes'}
      disableNextMenu={(value) => value == 'No'}
      onSequentialChange={onSequentialChange}
      listOfQs={listOfQs}
    />
  ) : (
    <></>
  );
};

export default ({ location }) => {
  const { salesManId, retailerId, auditId, date } = qs.parse(location.search);
  const [selectedRetailer, setSelectedRetailer] = useState();
  const [notif] = useSnackbar();
  const { data: retailersData } = useQuery(
    `auditing_retailers_data${salesManId}${date}`,
    () =>
      fetch(`/retailer/audit/salesman/${salesManId}/retailer?date=${date}`)
        .then((res) => res)
        .catch((err) => {
          notif(err.message, { variant: 'error' });
        }),
    {
      suspense: false,
    }
  );
  useEffect(() => {
    if (retailersData) {
      setSelectedRetailer(retailersData.find((r) => r.retailerId == retailerId));
    }
    return () => {
      setSelectedRetailer();
    };
  }, [retailerId, retailersData]);

  return (
    <>
      {!isInIframe() && (
        <AppBar position="sticky">
          <Toolbar variant="dense">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() =>
                navigate(`/ct/retailers/audit/?salesManId=${salesManId}&retailerId=${retailerId}`)
              }
              edge="start"
            >
              <ArrowBackIcon />
            </IconButton>

            <Typography variant="h6">Audit Questionnaires</Typography>
          </Toolbar>
        </AppBar>
      )}
      <NoSsr>
        <Suspense fallback={<LinearProgress color="secondary" />}>
          {selectedRetailer && <AuditQA retailer={selectedRetailer} auditId={auditId} />}
        </Suspense>
      </NoSsr>
    </>
  );
};

const AuditQA = ({ retailer, auditId }) => {
  const [fetch] = useAPI();
  const initialState = {
    shopDetails: {
      isOpen: '', //true/false
      willOpen: '', // true/false
      willOpenOnDate: '', // Date
      closedWindow: [], // [DateTime]
      permanentlyClosed: '', //true/false
    },
  };
  const [state, setState] = useState({
    ...{
      auditId,
      retailer: retailer,
      phoneNo: retailer.phone,
      isConnected: '',
      reason: '',
    },
    ...initialState,
  });
  const getVal = ({ val, bool }) =>
    bool
      ? val !== ''
        ? val.toUpperCase() === 'Yes'.toUpperCase() || val.toUpperCase() === 'True'.toUpperCase()
        : undefined
      : val !== ''
      ? val
      : undefined;

  const getPayload = (state) => {
    return {
      phone: state.phoneNo,
      isConnected: getVal({ val: state.isConnected, bool: true }),
      reason: getVal({ val: state.reason }),
      auditId: getVal({ val: state.auditId }),
      shopDetails: {
        isOpen: getVal({ val: state.shopDetails.isOpen, bool: true }), //true/false
        willOpen: getVal({ val: state.shopDetails.willOpen, bool: true }), // true/false
        willOpenOnDate: getVal({ val: state.shopDetails.willOpenOnDate }), // Date
        permanentlyClosed: getVal({ val: state.shopDetails.permanentlyClosed, bool: true }), //true/false
        closedWindow: state.shopDetails.closedWindow,
      },
    };
  };
  const handelSubmit = () => {
    fetch(
      {
        method: 'post',
        url: `/retailer/audit/${auditId}/accept`,
        data: getPayload(state),
      },
      () => {
        const redirect = window.confirm('Audit accepted successfully! Want to go back?');
        if (redirect) navigate(`/ct/auditingDashboard`);
      }
      // (error) => console.log('Error while creating new report :', error)
    );
  };
  const isSubmittable = () => {
    if (state.isConnected == 'No' && state.reason !== '') return true;
    if (
      state.isConnected == 'Yes' &&
      ((state.shopDetails.isOpen == 'Yes' && !isEmpty(state.shopDetails.closedWindow)) ||
        (state.shopDetails.willOpen == 'Yes' && state.shopDetails.willOpenOnDate !== '') ||
        state.shopDetails.permanentlyClosed == 'Yes')
    ) {
      return true;
    }
    return false;
  };
  return (
    <Box p={5} justifyContent="center" alignSelf="center" style={{ width: '85%', margin: 'auto' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          Contact No
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            style={{ marginLeft: '1%' }}
            defaultValue={state.phoneNo}
            inputProps={{ 'aria-label': 'description' }}
            onChange={(e) => {
              const { value } = e.target;
              setState((preState) => ({ ...preState, phoneNo: value }));
            }}
          />
          <IconButton color="primary" href={`tel:${state.phoneNo}`} aria-label="call" component="a">
            <CallIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} md={6}>
          Connected to Retailer{' '}
          <i>
            <b> {`"${retailer.name}"`} </b>
          </i>
          (Y/N) ?
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomSelect
            items={binaryInput}
            value={state.isConnected}
            onChange={(event) =>
              setState((preState) => ({
                ...preState,
                isConnected: event.target.value,
                ...initialState,
              }))
            }
          />
        </Grid>
        <Questionnaires
          connected={state.isConnected}
          reason={state.reason}
          onReasonChange={(event) =>
            setState((preState) => ({ ...preState, reason: event.target.value }))
          }
          onSequentialChange={(value) => {
            setState((preState) => ({ ...preState, shopDetails: { ...value } }));
          }}
          listOfQs={listOfQAs}
        />
      </Grid>
      <Box mt={2}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handelSubmit}
          disabled={!isSubmittable()}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};
