import React from 'react';
import { Box } from '@material-ui/core';
import { DatePicker as DatePickerMUI } from '@material-ui/pickers';
import { getCustomDateRegExp } from 'utils/utils';
import { makeStyles } from '@material-ui/styles';
import TodayIcon from '@material-ui/icons/Today';

const useStyles = makeStyles(() => ({
  date: {
    width: (props) => props.width || 150,
    '& .MuiOutlinedInput-input': {
      paddingTop: 6.5,
      paddingBottom: 6.5,
      color: (props) => props.color || '#31405c',
      textAlign: 'center',
    },
  },
}));

const DatePicker = ({ color, width, ...rest }) => {
  const classes = useStyles({ color, width });
  return (
    <Box mr={2}>
      <DatePickerMUI
        inputFormat={rest.inputFormat || 'MMM d'}
        variant="outlined"
        desktopModeBreakpoint="sm"
        autoOk
        className={classes.date}
        acceptRegex={getCustomDateRegExp()}
        keyboardIcon={<TodayIcon style={{ color: color || '#31405c' }} />}
        mask="___ _"
        {...rest}
      />
    </Box>
  );
};

export default DatePicker;
