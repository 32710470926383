import React from 'react';

import InputBase from '@material-ui/core/InputBase';
import { withStyles } from '@material-ui/core/styles';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: '0.8rem',
    padding: '10px 30px 10px 12px',
    marginLeft: theme.spacing(1),
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const CustomSelect = (props) => {
  return (
    <Select
      style={{ minWidth: '50%', align: 'center' }}
      labelId="demo-customized-select-label"
      id="demo-customized-select"
      placeholder="Please select"
      input={<BootstrapInput />}
      {...props}
    >
      {props.items
        .filter((it) => !it.hide)
        .map((item, index) => (
          <MenuItem key={++index} value={item.value || item.label}>
            {item.label}
          </MenuItem>
        ))}
    </Select>
  );
};

export default CustomSelect;
