import React from 'react';
import { Router } from '@reach/router';
import AuditRetailers from 'delivery/auditing/AuditRetailers';
import RetailerAudit from 'delivery/auditing/RetailerAudit';
import AuditQA from 'delivery/auditing/AuditQA';

export default function retailers(props) {
  return (
    <Router>
      <AuditRetailers {...props} path="/ct/retailers" />
      <RetailerAudit {...props} path="/ct/retailers/audit" />
      <AuditQA {...props} path="/ct/retailers/auditqa" />
    </Router>
  );
}
